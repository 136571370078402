<template>
  <div>
    <!-- FORM -->
    <v-form ref="form">
      <v-card class="mt-2">
        <v-card-title
          ><div>Perustiedot</div>
          <v-spacer></v-spacer>
          <router-link
            v-if="$route.path.includes('/condominium/edit')"
            :to="`/condominium/overview/${$route.params.id}`"
          >
            <v-btn color="primary">Yleisnäkymään</v-btn>
          </router-link>
        </v-card-title>

        <v-card-subtitle
          >** Julkiset tiedot voidaan näyttää, jos kohde julkaistaan asuntoportaaleihin tai omille
          verkkosivuille</v-card-subtitle
        >
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-checkbox
                :disabled="isDisabled('condominium', 'fetchFromYtj')"
                v-model="fetchFromYtj"
                label="Salli YTJ:n hakea tietoja"
                style="margin-top: 0"
              ></v-checkbox>

              <v-text-field
                :disabled="isDisabled('condominium', 'businessId')"
                class="mb-1"
                v-model="currentCondominium.businessId"
                label="Y-tunnus"
                @input="checkYTJ"
                @change="checkYTJ"
                outlined
                dense
                hide-details
              ></v-text-field>
              <small
                >Esitäytä tietoja syöttämällä y-tunnus. Palvelu hakee taloyhtiön tietoja
                YTJ-palvelusta.</small
              >
            </v-col>
          </v-row>

          <h4 class="mt-1">Perustiedot</h4>

          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'name')"
                v-model="currentCondominium.name"
                :rules="validations.required"
                label="Nimi"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentCondominium.buildYear"
                type="number"
                step="1"
                label="Rakennusvuosi **"
                min="1800"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentCondominium.plotArea"
                type="number"
                step="0.01"
                label="Tontin pinta-ala**"
                suffix="m²"
                min="1"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <h4 class="mt-1">Osoite</h4>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-autocomplete
                v-model="nominatimAddress"
                :disabled="isDisabled('condominium', 'address')"
                :items="foundAddresses"
                :item-text="nominatimHelpers.getAddressListName"
                outlined
                label="Hae osoitteella"
                dense
                return-object
                no-filter
                @change="
                  setAddress($event);
                  clearInput();
                "
                @input.native="getAddresses"
                hide-details
              ></v-autocomplete> </v-col
          ></v-row>

          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'address')"
                v-model="currentCondominium.address"
                outlined
                dense
                placeholder="Osoite"
                id="autocomplete"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'zipCode')"
                v-model="currentCondominium.zipCode"
                label="Postinumero"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'city')"
                v-model="currentCondominium.city"
                label="Kaupunki"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentCondominium.neighborhood"
                label="Kaupunginosa"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-autocomplete
                :disabled="isDisabled('condominium', 'country')"
                v-model="currentCondominium.country"
                :items="getCountries()"
                outlined
                clearable
                label="Maa"
                dense
                hide-details
                :rules="[isRequiredByInvoiceService({ ropoCapital: false, talenom: true })]"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <h4 class="mt-1">Muut tiedot</h4>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentCondominium.otherRooms"
                placeholder="Pyykkitupa, pyöräkellari, kuntosali..."
                label="Muut tilat"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentCondominium.floors"
                v-only-numbers-and-zero
                v-prevent-paste
                label="Kerroksia **"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-select
                v-model="currentCondominium.elevator"
                label="Hissi **"
                :items="[
                  { val: true, text: 'Kyllä' },
                  { val: false, text: 'Ei' },
                ]"
                item-value="val"
                item-text="text"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-select
                v-model="currentCondominium.sauna"
                label="Taloyhtiösauna **"
                :items="[
                  { val: true, text: 'Kyllä' },
                  { val: false, text: 'Ei' },
                ]"
                item-value="val"
                item-text="text"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentCondominium.heating"
                label="Lämmitys **"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentCondominium.antennaSystem"
                label="Antennijärjestelmä **"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="2" xl="1">
              <v-text-field
                v-model="currentCondominium.energyClass"
                label="Energialuokka **"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentCondominium.parkingPlaces"
                label="Autopaikat"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-textarea
                v-model="currentCondominium.repairsDone"
                label="Suoritettu korjaukset"
                dense
                outlined
                hide-details
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-textarea
                v-model="currentCondominium.repairsWaiting"
                label="Tulevat korjaukset"
                dense
                outlined
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>Hinnat ja lainat</v-card-title>
        <v-container>
          <h4>Hintatiedot</h4>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentCondominium.maintenancePerSquareCost"
                step="0.01"
                type="number"
                label="Hoitovastike"
                outlined
                dense
                suffix="€/m²"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-select
                v-model="currentCondominium.waterBasis"
                :items="['Kulutuksen mukaan', 'Kiinteä']"
                label="Vesimaksun peruste"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentCondominium.waterCost"
                step="0.01"
                type="number"
                label="Vesimaksu/ennakko **"
                outlined
                dense
                suffix="€/hlö"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentCondominium.saunaCost"
                step="0.01"
                type="number"
                label="Saunavuoron hinta"
                outlined
                dense
                suffix="€"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentCondominium.parkingCost"
                step="0.01"
                type="number"
                label="Parkkipaikan hinta"
                outlined
                dense
                suffix="€/kk"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentCondominium.laundretteCost"
                step="0.01"
                type="number"
                label="Pyykkituvan hinta"
                outlined
                dense
                suffix="€"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentCondominium.mediaCost"
                step="0.01"
                type="number"
                label="Mediamaksu"
                outlined
                dense
                suffix="€"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Condominium loans -->
          <condominium-loans ref="condominiumLoans" class="mt-1"></condominium-loans>
        </v-container>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>Laskutus</v-card-title>
        <v-container>
          <div v-if="showServiceAccountOptions">
            <h4>Laskutustili</h4>
            <v-row dense class="align-center">
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-checkbox
                  v-model="updateServiceAccountId"
                  :disabled="isDisabled('condominium', 'invoiceData')"
                  label="Päivitä laskutustiliä"
                  outlined
                  dense
                  hide-details
                ></v-checkbox>
              </v-col>

              <v-col
                v-if="!updateServiceAccountId && currentServiceAccount"
                cols="12"
                sm="6"
                md="4"
                lg="3"
                xl="2"
              >
                <p style="margin-bottom: 0px !important">
                  <strong>Valittu laskutustili: </strong> {{ currentServiceAccount }}
                </p>
              </v-col>

              <v-col v-if="updateServiceAccountId" cols="12">
                <small>
                  Mikäli haluat käyttää taloyhtiön / kiinteistön vuokrakohteiden laskutuksessa eri
                  laskutustiliä kuin oletustili, valitse se listasta.</small
                >
              </v-col>
            </v-row>

            <template v-if="updateServiceAccountId">
              <v-row dense>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-select
                    v-model="selectedServiceAccountId"
                    :items="serviceAccounts"
                    :disabled="isDisabled('condominium', 'invoiceData')"
                    item-value="cid"
                    item-text="name"
                    label="Valitse tili"
                    outlined
                    dense
                    :rules="updateServiceAccountId ? validations.required : []"
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-select
                    v-model="serviceAccountUpdateMethod"
                    :items="serviceAccountUpdateMethods"
                    :disabled="isDisabled('condominium', 'invoiceData')"
                    item-value="val"
                    item-text="text"
                    label="Valitse päivitystapa"
                    outlined
                    dense
                    :rules="updateServiceAccountId ? validations.required : []"
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>

              <v-row dense v-if="selectedServiceAccountId && serviceAccountUpdateMethod">
                <v-col cols="12" md="6">
                  <warning-component
                    color="success"
                    icon="check"
                    :marginBottom="costCentreUpdateText ? '0' : '16'"
                  >
                    {{ serviceAccountUpdateText }}
                  </warning-component>
                </v-col>
                <v-col cols="12" md="6">
                  <warning-component :marginBottom="costCentreUpdateText ? '0' : '16'">
                    Tiliä ei päivitetä automaattisesti toistuville laskuille. Muokkaa laskut
                    erikseen laskutuksen kautta.
                  </warning-component>
                </v-col>
              </v-row>

              <v-row dense v-if="costCentreUpdateText">
                <v-col cols="12" md="6">
                  <warning-component>
                    {{ costCentreUpdateText }}
                  </warning-component>
                </v-col>
              </v-row>
            </template>
          </div>

          <h4 :class="showServiceAccountOptions ? 'mt-1' : ''">Kustannuspaikka</h4>

          <v-row dense>
            <v-col cols="12">
              <small>
                Jos olet valinnut tilin laskutusasetuksista kiinteistökohdekohtaisen
                kustannuspaikkalaskennan, käytetään kyseisen kiinteistön vuokrakohteiden
                laskutuksessa tätä kustannuspaikkaa.
              </small>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'costCentre')"
                v-model="currentCondominium.costCentre"
                :label="costCentreFieldLabel"
                outlined
                dense
                hide-details
              ></v-text-field>
              <p>
                <small
                  >Muokattaessa kustannuspaikka ei päivity automaattisesti laskuille. Mene tilin
                  asetukset -> laskutus ja tarkista kustannuspaikan asetukset.</small
                >
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>Yhteystiedot</v-card-title>
        <v-container>
          <h4 class="mt-1">Isännöinti</h4>

          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.companyName"
                label="Yritys"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.name"
                label="Isännöitsijä"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.phone"
                label="Puhelin"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.email"
                label="Email"
                :rules="validations.email2"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <h4 class="mt-1">Kiinteistösihteeri/Kirjanpitäjä</h4>

          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.accountantCompany"
                label="Yritys"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.accountantName"
                label="Kirjanpitäjä"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.accountantPhone"
                label="Puhelin"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.accountantEmail"
                label="Email"
                :rules="validations.email2"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <h4 class="mt-1">Huolto</h4>

          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.propertyMaintenanceCompany"
                label="Huoltoyhtiö"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.janitor"
                label="Huoltomies"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.janitorPhone"
                label="Puhelin"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.janitorEmail"
                label="Email"
                :rules="validations.email2"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <h4 class="mt-1">Sähköpostiviestien vastaanottajat</h4>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-select
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.waterEqualizationEmailReceivers"
                :items="waterEmailreceivers"
                label="Vesitasauksen laskupyynnön vastaanottajat"
                item-text="text"
                item-value="val"
                multiple
                small-chips
                deletable-chips
                outlined
                hide-details
              ></v-select>
              <small v-if="noWaterReceiverEmail" class="error--text font-weight-bold"
                >Jonkin vastaanottajan sähköposti puuttuu!</small
              >
            </v-col>
          </v-row>

          <h4 class="mt-1">Muut huomiot</h4>
          <v-row dense>
            <v-col cols="12" sm="6" md="4">
              <v-textarea
                :disabled="isDisabled('condominium', 'notes')"
                v-model="currentCondominium.notes"
                rows="5"
                outlined
                dense
                label="Vapaa tekstikenttä..."
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>

          <v-btn color="info" class="mr-4 mt-5" @click="submit">{{ saveBtnText }}</v-btn>
        </v-container>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import invoiceMixins from "../../mixins/invoiceMixins";
import { mapState, mapMutations, mapActions } from "vuex";
import { debounce } from "../../utils/helpers";
import nominatimHelpers from "@/utils/nominatimHelpers";
import validations from "@/validations";
import CondominiumLoans from "./CondominiumLoans.vue";
import Vue from "vue";
// import { Loader } from "@googlemaps/js-api-loader";

export default {
  mixins: [mixins, invoiceMixins],

  components: {
    CondominiumLoans,
  },

  props: {
    cond: {
      type: Object,
      default: function () {
        return {};
      },
    },
    saveBtnText: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      addressInput: "",
      fetchFromYtj: false,
      waterEmailreceivers: [
        { text: "Isännöitsijä", val: "administrator" },
        { text: "Kirjanpitäjä", val: "accountant" },
        { text: "Huolto", val: "janitor" },
        { text: "Vuokrakohteen omistajat (Toimeksiantajat)", val: "owners" },
      ],
      originalValuesSet: false,
      originalServiceAccount: undefined,
      costCentreUpdateText: "",
      updateServiceAccountId: false,
      serviceAccountUpdateMethod: "",
      serviceAccountUpdateMethods: [
        { text: "Kaikkiin", val: "all" },
        { text: "Tyhjiin", val: "empty" },
      ],
      nominatimHelpers,
      validations,
    };
  },

  created() {
    this.updateServiceAccountId = false;
    this.originalValuesSet = false;
    this.originalServiceAccount = undefined;
    this.costCentreUpdateText = "";
  },

  computed: {
    ...mapState("account", ["currentUser"]),
    ...mapState("condominium", ["currentCondominium"]),
    ...mapState("nominatim", ["foundAddresses"]),

    showServiceAccountOptions() {
      if (
        this.showBasedOnService({ ropoCapital: true, talenom: false }) &&
        this.serviceAccounts.length > 1 &&
        this.$route.path.includes("edit")
      )
        return true;
      else return false;
    },

    serviceAccounts() {
      return this.getServiceAccounts();
    },

    selectedServiceAccountId: {
      get() {
        const documentPath = this.getServiceAccountsIdDocumentPath();
        return this.getValueFromPath(documentPath);
      },
      set(id) {
        const documentPath = this.getServiceAccountsIdDocumentPath();
        const pathParts = documentPath.split(".");
        let obj = this.currentCondominium;

        while (pathParts.length > 1) {
          const key = pathParts.shift();
          if (!obj[key]) {
            Vue.set(obj, key, {});
          }
          obj = obj[key];
        }

        Vue.set(obj, pathParts[0], id);
      },
    },

    serviceAccountUpdateText() {
      if (this.serviceAccountUpdateMethod === "empty")
        return "Tili päivitetään kaikkiin kiinteistön vuokrakohteisiin, joille ei ole valittu vielä laskutustiliä.";
      else if (this.serviceAccountUpdateMethod === "all")
        return "Tili päivitetään kaikkiin kiinteistön vuokrakohteisiin.";
      return "";
    },

    currentServiceAccount() {
      if (this.selectedServiceAccountId) {
        const account = this.getServiceAccountByServiceId(
          this.serviceAccounts,
          this.selectedServiceAccountId
        );
        if (account) return account.name;
      }

      return null;
    },

    nominatimAddress: {
      get() {
        return this.$store.state.nominatim.nominatimAddress;
      },
      set(val) {
        this.setNominatimAddress(val);
      },
    },

    noWaterReceiverEmail() {
      const receivers = this.currentCondominium?.administrator?.waterEqualizationEmailReceivers;
      if (
        receivers &&
        receivers.includes("administrator") &&
        this.currentCondominium.administrator.email == ""
      )
        return true;

      if (
        receivers &&
        receivers.includes("accountant") &&
        this.currentCondominium.administrator.accountantEmail == ""
      )
        return true;
      if (
        receivers &&
        receivers.includes("janitor") &&
        this.currentCondominium.administrator.janitorEmail == ""
      )
        return true;
      return false;
    },
  },

  watch: {
    currentCondominium() {
      if (!this.originalValuesSet) {
        const documentPath = this.getServiceAccountsIdDocumentPath();
        this.originalServiceAccount = this.getValueFromPath(documentPath);
        this.originalValuesSet = true;
        this.setLoading(false);
      }
    },

    selectedServiceAccountId(newVal) {
      if (
        this.currentUser?.currentAccount?.settings?.useCondominiumCostAccounting &&
        this.originalServiceAccount !== newVal
      ) {
        this.costCentreUpdateText = `Käytät kiinteistökohtaista kustannuspaikan laskentaa ja olet vaihtamassa laskutustiliä.
        Kaikki aktiiviset laskut, joilla nykyinen kiinteistön kustannuspaikka on käytössä, asetetaan vaatii toimenpiteitä tilaan.
        Muuta kiinteistön kustannuspaikka uuden laskutusprofiilin kustannuspaikkaa vastaavaksi ja päivitä sen jälkeen toimenpiteitä vaativat laskut.`;
      } else {
        this.costCentreUpdateText = "";
      }
    },

    fetchFromYtj(val) {
      if (val) this.checkYTJ();
    },

    addressInput: debounce(function (newVal) {
      this.getInfoByAddress(newVal);
    }, 400),
  },

  methods: {
    ...mapMutations("condominium", ["setField", "setLoading"]),
    ...mapActions("nominatim", ["getInfoByAddress"]),
    ...mapActions("report", ["getYTJData"]),
    ...mapMutations("nominatim", ["setNominatimAddress"]),

    submit() {
      if (this.$refs.form.validate()) {
        this.$emit("sendcondominium", {
          updateServiceAccountId: this.updateServiceAccountId,
          serviceAccountUpdateMethod: this.serviceAccountUpdateMethod,
        });
      } else {
        this.showPopup("Jotkin kentät puuttuvat tai ovat virheellisiä", "error");
      }
    },

    getValueFromPath(path) {
      return path
        .split(".")
        .reduce(
          (obj, key) => (obj && obj[key] !== undefined ? obj[key] : undefined),
          this.currentCondominium
        );
    },

    getAddresses(event) {
      this.addressInput = event.target.value;
    },

    setAddress(data) {
      if (data) {
        const { address, zipCode, city, country } = nominatimHelpers.getAddressData(data);
        this.setField({ field: "address", val: address });
        this.setField({ field: "zipCode", val: zipCode });
        this.setField({ field: "city", val: city });
        this.setField({ field: "country", val: country });
      }
    },

    clearInput() {
      this.$nextTick(() => {
        this.setNominatimAddress(null);
      });
    },

    async checkYTJ() {
      if (this.fetchFromYtj) {
        if (
          this.currentCondominium.businessId &&
          this.currentCondominium.businessId.length >= 9 &&
          this.currentCondominium.businessId.includes("-")
        ) {
          try {
            const { data } = await this.getYTJData(this.currentCondominium.businessId);

            this.setField({ field: "name", val: data.names[0].name });

            let administrator = data.addresses.filter((el) => el.co != null || el.co != "");
            if (administrator[0]) {
              this.setField({ field: "administrator.companyName", val: administrator[0].co });
            }

            setTimeout(() => {
              this.$forceUpdate();
            }, 100);
          } catch (err) {
            this.showPopup(
              "Kaikkia kenttiä tai osaa kenttien tiedoista ei voitu täyttää.",
              "error"
            );
          }
        }
      }
    },
  },
};
</script>

<style scoped></style>
